var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body_section"},[_c('profileHeader',{attrs:{"showFilter":false},on:{"changedGlobalclient":_vm.changedGlobalclient}}),_c('div',{staticClass:"body_content"},[_c('div',{staticClass:"main_content"},[_c('div',{staticClass:"subheader"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.serch_title),expression:"serch_title"}],staticClass:"search",attrs:{"type":"text","placeholder":"Search by name or email or phone"},domProps:{"value":(_vm.serch_title)},on:{"keyup":function($event){return _vm.serchTitle()},"input":function($event){if($event.target.composing){ return; }_vm.serch_title=$event.target.value}}})]),_c('div',{staticClass:"d-flex"},[_c('tableheader',{on:{"applyFilter":_vm.applyFilters,"clearFilter":_vm.clearFilters}}),(_vm.isLoaded && (_vm.userdata.roleId == 1 || _vm.userdata.roleId == 3))?_c('button',{staticClass:"primary_btn",on:{"click":function($event){return _vm.onPopup()}}},[_vm._v(" ADD USER ")]):_vm._e()],1)]),_c('div',{staticClass:"list_table"},[_c('table',{staticClass:"table table-hover"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_c('span',[_c('a',{class:{
                      sort_ascending: _vm.sortKeys['name'] == 1,
                      sort_descending: _vm.sortKeys['name'] != 1,
                    },on:{"click":function($event){return _vm.sortMe('name')}}},[_vm._v("Name")])])]),_c('th',{attrs:{"scope":"col"}},[_c('span',[_c('a',{class:{
                      sort_ascending: _vm.sortKeys['roleName'] == 1,
                      sort_descending: _vm.sortKeys['roleName'] != 1,
                    },on:{"click":function($event){return _vm.sortMe('roleName')}}},[_vm._v("Role")])])]),_c('th',{attrs:{"scope":"col"}},[_c('span',[_c('a',{class:{
                      sort_ascending: _vm.sortKeys['email'] == 1,
                      sort_descending: _vm.sortKeys['email'] != 1,
                    },on:{"click":function($event){return _vm.sortMe('email')}}},[_vm._v("Email")])])]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Phone")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Status ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Assigned Clients")]),_c('th',{staticClass:"w-80",attrs:{"scope":"col"}},[_vm._v("Actions")])])]),_c('tbody',[(_vm.usersList && _vm.usersList.length > 0)?_vm._l((_vm.usersList),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"d-flex"},[_c('router-link',{staticClass:"d-inline-flex",attrs:{"to":'/user-details/' + item['_id']}},[_c('figure',{staticClass:"d-inline-flex"},[_c('label',[_c('img',{attrs:{"src":item.photo},on:{"error":function($event){_vm.getProfilePhoto(
                              $event,
                              _vm.checkProperty(item, 'gender')
                            )}}})]),_c('figcaption',[_vm._v(_vm._s(item.name))])])])],1),_c('td',[_vm._v(_vm._s(item.roleDetails.name))]),_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[(item.phone)?_c('span',[_vm._v(" "+_vm._s(_vm.checkCharacter(item.phoneCode, "+"))+" "+_vm._s(item.phoneCode ? " - " : "")+" "+_vm._s(item.phone)+" ")]):_c('span',[_vm._v(" - ")])]),_c('td',[_c('span',{staticClass:"td_status",class:{
                      pending: item.statusId == 1,
                      active: item.statusId == 2,
                      inactive: item.statusId == 3,
                      deleted: item.statusId == 4,
                    }},[_vm._v(_vm._s(item["statusDetails"]["name"])+" ")])]),_c('td',[(
                      item.assignedClients && item.assignedClients.length > 0
                    )?_c('div',{staticClass:"td_chips"},[_vm._l((item.assignedClients),function(item1,ind){return [(ind < 3)?_c('div',{key:ind},[_c('span',{attrs:{"id":item._id + item1._id}},[_vm._v(_vm._s(item1.shortName)+" ")]),_c('b-popover',{attrs:{"target":item._id + item1._id,"triggers":"hover","placement":"top"}},[_vm._v(" "+_vm._s(_vm.checkProperty(item1, "name"))+" ")])],1):_vm._e()]}),_c('div',{staticClass:"td_hidden_chips"},[(item.assignedClients.length > 3)?[_c('span',{attrs:{"id":"popover-target-1"}},[_vm._v("+"+_vm._s(item.assignedClients.length - 3)+" ")])]:_vm._e(),(item.assignedClients.length > 3)?_c('b-popover',{attrs:{"target":"popover-target-1","triggers":"hover","placement":"bottom"}},[_c('div',{staticClass:"td_hidden_chips_list"},[_c('ul',[_vm._l((item.assignedClients),function(item1,ind){return [(ind >= 3)?_c('li',{key:ind},[_vm._v(" "+_vm._s(item1.shortName)+" ")]):_vm._e()]})],2)])]):_vm._e()],2)],2):_vm._e()]),_c('td',[_c('b-dropdown',{attrs:{"right":"","dropdown":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{attrs:{"icon":"three-dots-vertical","aria-hidden":"true"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.onPopup2(item)}}},[_c('router-link',{attrs:{"to":""}},[_vm._v("Edit")])],1),_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":'/user-details/' + item['_id']}},[_vm._v("DETAILS")])],1),(
                        (_vm.userdata.roleId == 1) &
                          (item.statusId == 2 || item.statusId == 3)
                      )?_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.onPopup3(item)}}},[_vm._v(" "+_vm._s(item.statusId == 2 ? "Deactivate" : item.statusId == 3 ? "Activate" : "")+" ")]):_vm._e()],1)],1)])}):_vm._e()],2)]),(_vm.showLoading)?[_vm._m(0)]:_vm._e(),(_vm.usersList.length <= 0 && _vm.loaded && !_vm.showLoading)?[_c('span',{staticClass:"no_data"},[_vm._v("No Users Found!")])]:_vm._e()],2),_c('div',{staticClass:"pagination_wrap"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.usersList.length > 0),expression:"usersList.length > 0"}]},[_c('div',{staticClass:"pagination_content"},[_c('h6',[_vm._v("Show Records")]),_c('multiselect',{attrs:{"multiple":false,"placeholder":"Page","close-on-select":true,"clear-on-select":false,"hide-selected":true,"preserve-search":true,"options":_vm.perPeges},on:{"input":function($event){return _vm.changePerPage()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var values = ref.values;
                      var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__selectcustom"},[_vm._v(_vm._s(values.length)+" selected")]):_vm._e(),(values.length && isOpen)?_c('span',{staticClass:"multiselect__selectcustom"}):_vm._e()]}}]),model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)]),(_vm.usersList.length > 0)?_c('b-pagination',{attrs:{"total-rows":_vm.totalPages + 1,"per-page":_vm.perPage,"pills":"","first-number":"","last-number":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1),_c('b-modal',{ref:"my-modal",attrs:{"centered":"","hide-footer":"","hide-header":""}},[_c('div',{staticClass:"create_popup"},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"modal_left"},[_c('div',{staticClass:"modal_title"},[_c('h3',[_vm._v(" Internal"),_c('br'),_vm._v(" Users ")])]),_c('figure',[_c('img',{attrs:{"src":require("@/assets/images/client_vector.svg")}})]),_c('span',{staticClass:"userlabel"},[_c('img',{attrs:{"src":require("@/assets/images/user_group.svg")}})])]),_c('div',{staticClass:"modal_right"},[_c('b-button',{staticClass:"close",attrs:{"variant":"outline-danger","block":""},on:{"click":_vm.hideModal}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_c('h2',[_vm._v("Add User")]),_c('form',{attrs:{"autocomplete":"off"}},[_c('ValidationObserver',{ref:"addUserForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var invalid = ref.invalid;
                      var validated = ref.validated;
                      var handleSubmit = ref.handleSubmit;
                      var validate = ref.validate;
return [_c('div',{staticClass:"form-group name-group"},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.fName),expression:"user.fName"}],staticClass:"form-control firstname",attrs:{"type":"text","aria-describedby":"emailHelp","placeholder":"First Name"},domProps:{"value":(_vm.user.fName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "fName", $event.target.value)}}}),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.lName),expression:"user.lName"}],staticClass:"form-control lastname",attrs:{"type":"text","id":"LastName","aria-describedby":"emailHelp","placeholder":"Last Name"},domProps:{"value":(_vm.user.lName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "lName", $event.target.value)}}}),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control email",attrs:{"type":"email","solo":"","label":"Email","placeholder":"Email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group phone-group"},[_c('ValidationProvider',{attrs:{"name":"Phone Number","rules":"required|phonenumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"default-country-code":"US","no-example":true,"no-country-selector":true,"required":true},on:{"update":_vm.updatePhoneNumber},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('span',{staticClass:"v-messages error-msg ddd"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group designation-group"},[_c('ValidationProvider',{attrs:{"name":"Designation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('multiselect',{attrs:{"value":'_id',"options":_vm.user_role,"searchable":true,"close-on-select":true,"allow-empty":false,"label":"name","placeholder":"Select Designation","track-by":"name"},on:{"input":_vm.updateRole},model:{value:(_vm.user.roleDetails),callback:function ($$v) {_vm.$set(_vm.user, "roleDetails", $$v)},expression:"user.roleDetails"}}),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('span',{staticClass:"primary_btn",attrs:{"disabled":invalid || !validated || _vm.formSubmited || _vm.checkForm},on:{"click":function($event){_vm.erororVisible = false;
                      validate();
                      handleSubmit(_vm.addUser);}}},[_vm._v("Create User")])]}}])})],1)],1)])])]),_c('b-modal',{ref:"my-modal2",attrs:{"centered":"","hide-footer":"","hide-header":"","id":"edituser"}},[_c('div',{staticClass:"create_popup edituser"},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"modal_left"},[_c('div',{staticClass:"modal_title"},[_c('h3',[_vm._v(" Internal"),_c('br'),_vm._v(" Users ")])]),_c('figure',[_c('img',{attrs:{"src":require("@/assets/images/client_vector.svg")}})]),_c('span',{staticClass:"userlabel"},[_c('img',{attrs:{"src":require("@/assets/images/user_group.svg")}})])]),_c('div',{staticClass:"modal_right"},[_c('b-button',{staticClass:"close",attrs:{"variant":"outline-danger","block":""},on:{"click":_vm.hideModal2}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_c('h2',[_vm._v("Edit User")]),_c('form',{attrs:{"autocomplete":false}},[_c('ValidationObserver',{ref:"updateUserForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var invalid = ref.invalid;
                      var validated = ref.validated;
                      var validate = ref.validate;
return [_c('div',{staticClass:"form-group name-group"},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateUser.fName),expression:"updateUser.fName"}],staticClass:"form-control firstname",attrs:{"type":"text","aria-describedby":"emailHelp","placeholder":"First Name"},domProps:{"value":(_vm.updateUser.fName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateUser, "fName", $event.target.value)}}}),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateUser.lName),expression:"updateUser.lName"}],staticClass:"form-control lastname",attrs:{"type":"text","id":"LastName","aria-describedby":"emailHelp","placeholder":"Last Name"},domProps:{"value":(_vm.updateUser.lName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateUser, "lName", $event.target.value)}}}),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group phone-group"},[_c('ValidationProvider',{attrs:{"name":"Phone Number","rules":"required|phonenumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"default-country-code":"US","no-example":true,"no-country-selector":true,"required":true},on:{"update":_vm.updatePhoneNumber},model:{value:(_vm.updateUser.phone),callback:function ($$v) {_vm.$set(_vm.updateUser, "phone", $$v)},expression:"updateUser.phone"}}),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('span',{staticClass:"primary_btn",attrs:{"disabled":invalid || !validated || _vm.formSubmited || _vm.checkForm},on:{"click":function($event){_vm.erororVisible = false;
                      validate();
                      _vm.userUpdate();}}},[_vm._v("Update User")])]}}])})],1)],1)])])]),_c('b-modal',{ref:"my-modal3",staticClass:"modal_popup",attrs:{"centered":"","hide-footer":"","hide-header":""}},[_c('div',{staticClass:"modal_popup profile_popup alert_popup"},[_c('b-button',{staticClass:"close",attrs:{"block":""},on:{"click":function($event){_vm.selectedItem = null;
              _vm.hideMe();}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_c('div',{staticClass:"profile_head"},[_c('label',[_vm._v("Do you want to "+_vm._s(_vm.actionTitle)+" this user.. ?")])]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{staticClass:"secondary_btn",on:{"click":function($event){_vm.selectedItem = null;
                _vm.hideMe();}}},[_vm._v(" No ")]),_c('button',{staticClass:"primary_btn marl15",on:{"click":function($event){return _vm.actionOnstatuschange()}}},[_vm._v(" Yes ")])])],1)])],1),_c('footerView',{ref:"rout_view"})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"no_data list_loader"},[_vm._v("Loading"),_c('img',{attrs:{"src":require("@/assets/images/loader.gif")}})])}]

export { render, staticRenderFns }