<template>
     <div class="filters_sec" :class="{'open':openFilter}">
        <button @click="toggleFilter()" class="filters_btn">Filters</button>
        <div class="filters_cnt ">
            <div class="row">
               <!-- <div class="filters_list col">
                    <h6>Designations</h6>
                    <ul>
                        <template  v-for="(item, index) in user_role">
                            <li v-bind:key="index">
                            <input v-bind:key="'A'+index" class="styled-checkbox" v-model="item.selected" :id="'styled-checkbox-4a'+index" @change="setPreference($event, item, 'selected_roles', 'user_role')" type="checkbox">
                            <label :for="'styled-checkbox-4a'+index" v-bind:key="'B'+index" >{{item.name}}</label>
                            </li> 
                        </template>
                    </ul>
                </div>-->
                <div class="filters_list col">
                    <h6>Status</h6>
                    <!--<ul>
                          <template  v-for="(item, index) in user_status">
                            <li v-bind:key="index">
                            <input v-bind:key="'A'+index" class="styled-checkbox" v-model="item.selected" :id="'styled-checkbox-4b'+index" @change="setPreference($event, item, 'selected_status', 'user_status')" type="checkbox">
                            <label :for="'styled-checkbox-4b'+index" v-bind:key="'B'+index">{{item.name}}</label>
                            </li> 
                        </template>
                    </ul>-->
                   
                    <multiselect
                    v-model="selected_status"
                        :value="'_id'"
                        :options="user_status"
                        :searchable="true"
                        :close-on-select="false"
                        :hide-selected="true"
                        :allow-empty="true"
                        :multiple="true"
                        
                        label="name"
                        placeholder="Select status"
                        track-by="name"
                    >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && !isOpen"
                        >{{ values.length }} status's selected</span
                        >
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && isOpen"
                        ></span>
                    </template>
                    </multiselect>
                </div>

                 <div class="filters_list col">
                    <h6>Created Date</h6>
                    <date-range-picker  :maxDate="new Date()" :opens="'left'" :autoApply="autoApply" :ranges="false"   v-model="selected_createdDateRange"></date-range-picker>
                </div>
            </div>
            <div class="filter_actions">
                <button class="secondary_btn" @click="clearFilter()">Clear<i class="fas fa-redo"></i></button>
                <div class="d-flex">
                    <button class="filter_close secondary_btn" @click="openFilter=false;toggleBodyClass('removeClass', 'hidden')">Cancel</button>
                    <button class="filter_close primary_btn" @click="applyFilters()">Apply</button>
                </div>
            </div>
        </div>
        <span class="filters_layer"  @click="toggleFilter()"></span>
    </div>
</template>
<script>
import _ from "lodash"; 
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import Multiselect from "vue-multiselect-inv";

export default {
    components: {
        DateRangePicker,
        Multiselect
    },
    data() {
        return {
            user_role: [],
            user_status: [],
            selected_roles: [],
            selected_status: [],
            selected_createdDateRange:{},
            autoApply: "",
        }
    },
    methods: {
        getMaterData(category){
           
            let payLoad = {
            "matcher": {
                "searchString":"",
            },
            "page": 1,
            "perpage": 10000000000,
            "category": category, // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
            } ;
            this.$store.dispatch("getMaterData",payLoad)
                .then((res)=>{
                // this[category] = res.list;
                
               
                 
                if(category =="user_role"){
                    //if loggied in user is super admin he can create only support team(2) AND CA admin (3)
                        if(this.userdata.roleId ==1){
                            this[category] =_.filter(res.list ,(rol)=>{
                                return rol._id ==2 || rol._id ==1
                                //return rol._id ==2 
                            });

                        }

                        //if loggied in user is CA admin he can create only Employes(4)
                        if(this.userdata.roleId ==3){
                            this[category] =_.filter(res.list ,{"_id":4 });

                        }
                }else if(category == "user_status"){

                    this.user_status.forEach(obj => {
                            obj.selected = false;
                    })
                    let data = res.list;
                    this[category]   = _.filter(data ,(obj)=>{
                        return obj['_id'] !=4 && obj['_id'] !=1;
                    })
                }else{

                   this[category] = res.list;  
                }
                                        
                })
                .catch((err)=>{
                    this.log(err);

                });
        },
        setPreference(event, item, selectedFltr, list) {
            
        /*if (item.selected) {
        if( this.selected_settings.indexOf(item._d) <=-1){
            this.selected_settings.push(item._id);
        }
        } else {
            if( this.selected_settings.indexOf(item._id) >=-1){
            this.selected_settings.splice(  this.selected_settings.indexOf(item._id) ,1 );
            _.forEach(this.settings_list ,(object ,index)=>{
            if(item._id == object._id){
                this.settings_list[index].selected = false;
            }
            })
        }

        }*/
        if (item.selected) {
        if( this[selectedFltr].indexOf(item._d) <=-1){
            this[selectedFltr].push(item._id);
        }
        } else {
            if( this[selectedFltr].indexOf(item._id) >=-1){
            this[selectedFltr].splice(  this[selectedFltr].indexOf(item._id) ,1 );
            _.forEach(this[list] ,(object ,index)=>{
            if(item._id == object._id){
                this[list][index].selected = false;
            }
            })
        }

        }
        console.log('selected_roles', this.selected_roles)
        console.log('selected_status', this.selected_status)
        },
        applyFilters() {
            this.toggleBodyClass('removeClass', 'hidden')
            let filterData = {'roleIds':[] , 'statusIds':[], 'createdOnRange': []}
            filterData.roleIds = this.selected_roles
          //  filterData.statusIds = this.selected_status
           if((_.has(this.selected_createdDateRange ,'startDate' ) && this.selected_createdDateRange['startDate']  && this.selected_createdDateRange['startDate'] !=null ) &&
             ( _.has(this.selected_createdDateRange ,'endDate' ) && this.selected_createdDateRange['endDate']  && this.selected_createdDateRange['endDate'] !=null ) 
             
             ){   filterData['createdOnRange'].push(moment(this.selected_createdDateRange['startDate']).format("YYYY-MM-DD"));
                filterData['createdOnRange'].push(moment(this.selected_createdDateRange['endDate']).format("YYYY-MM-DD"));
            }

            if(this.selected_status.length>0){
                _.forEach(this.selected_status ,(state)=>{
                    filterData['statusIds'].push(state['_id']);
                });
            }
            this.$emit("applyFilter" ,filterData);
            this.openFilter=false;
        },
        clearFilter() {
            this.toggleBodyClass('removeClass', 'hidden')
            this.selected_roles = [];
            this.selected_status = [];
            this.user_role.forEach(obj => {
                obj.selected = false;
            })
            this.user_status.forEach(obj => {
                obj.selected = false;
            })
            this.selected_createdDateRange = {startDate:null ,endDate:null};
            this.$emit("clearFilter");
            this.openFilter=false;
        }
    },
    mounted(){

      this.getMaterData("user_role");
      this.getMaterData("user_status");
      
  },
    
}
</script>