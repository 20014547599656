<template>
  <div class="body_section">
    <profileHeader
      :showFilter="false"
      @changedGlobalclient="changedGlobalclient"
    />
    <div class="body_content">
      <div class="main_content">
        <div class="subheader">
          <div>
            <input
              class="search"
              type="text"
              v-model="serch_title"
              @keyup="serchTitle()"
              placeholder="Search by name or email or phone"
            />
          </div>
          <div class="d-flex">
            <tableheader
              @applyFilter="applyFilters"
              @clearFilter="clearFilters"
            ></tableheader>
            <button
              v-if="isLoaded && (userdata.roleId == 1 || userdata.roleId == 3)"
              class="primary_btn"
              @click="onPopup()"
            >
              ADD USER
            </button>
          </div>
        </div>

        <div class="list_table">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">
                  <span>
                    <a
                      @click="sortMe('name')"
                      v-bind:class="{
                        sort_ascending: sortKeys['name'] == 1,
                        sort_descending: sortKeys['name'] != 1,
                      }"
                      >Name</a
                    ></span
                  >
                </th>
                <th scope="col">
                  <span>
                    <a
                      @click="sortMe('roleName')"
                      v-bind:class="{
                        sort_ascending: sortKeys['roleName'] == 1,
                        sort_descending: sortKeys['roleName'] != 1,
                      }"
                      >Role</a
                    ></span
                  >
                </th>
                <th scope="col">
                  <span>
                    <a
                      @click="sortMe('email')"
                      v-bind:class="{
                        sort_ascending: sortKeys['email'] == 1,
                        sort_descending: sortKeys['email'] != 1,
                      }"
                      >Email</a
                    ></span
                  >
                </th>
                <th scope="col">Phone</th>
                <th scope="col">
                  <!--<span>
                            <a @click="sortMe('statusName')"
                                v-bind:class="{
                                sort_ascending: sortKeys['statusName'] == 1,
                                sort_descending: sortKeys['statusName'] != 1,
                                }"
                                >Status</a
                            ></span>-->
                  Status
                </th>
                <th scope="col">Assigned Clients</th>
                <th scope="col" class="w-80">Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="usersList && usersList.length > 0">
                <tr v-for="(item, index) in usersList" :key="index">
                  <td class="d-flex">
                    <router-link
                      class="d-inline-flex"
                      :to="'/user-details/' + item['_id']"
                    >
                      <figure class="d-inline-flex">
                        <label
                          ><img
                            @error="
                              getProfilePhoto(
                                $event,
                                checkProperty(item, 'gender')
                              )
                            "
                            :src="item.photo"
                        /></label>
                        <!--<span></span>-->
                        <figcaption>{{ item.name }}</figcaption>
                      </figure>
                    </router-link>
                  </td>
                  <td>{{ item.roleDetails.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>
                    <span v-if="item.phone">
                      {{ checkCharacter(item.phoneCode, "+") }}
                      {{ item.phoneCode ? " - " : "" }} {{ item.phone }}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span
                      class="td_status"
                      :class="{
                        pending: item.statusId == 1,
                        active: item.statusId == 2,
                        inactive: item.statusId == 3,
                        deleted: item.statusId == 4,
                      }"
                      >{{ item["statusDetails"]["name"] }}
                    </span>
                  </td>
                  <td>
                    <div
                      v-if="
                        item.assignedClients && item.assignedClients.length > 0
                      "
                      class="td_chips"
                    >
                      <template v-for="(item1, ind) in item.assignedClients">
                        <div v-if="ind < 3" :key="ind">
                          <span :id="item._id + item1._id"
                            >{{ item1.shortName }}
                          </span>
                          <b-popover
                            :target="item._id + item1._id"
                            triggers="hover"
                            placement="top"
                          >
                            {{ checkProperty(item1, "name") }}
                          </b-popover>
                        </div>
                      </template>
                      <div class="td_hidden_chips">
                        <template v-if="item.assignedClients.length > 3">
                          <span id="popover-target-1"
                            >+{{ item.assignedClients.length - 3 }}
                          </span>
                        </template>
                        <b-popover
                          v-if="item.assignedClients.length > 3"
                          target="popover-target-1"
                          triggers="hover"
                          placement="bottom"
                        >
                          <div class="td_hidden_chips_list">
                            <ul>
                              <template
                                v-for="(item1, ind) in item.assignedClients"
                              >
                                <li v-if="ind >= 3" :key="ind">
                                  {{ item1.shortName }}
                                </li>
                              </template>
                            </ul>
                          </div>
                        </b-popover>
                      </div>
                    </div>
                  </td>
                  <td>
                    <b-dropdown right dropdown>
                      <template #button-content>
                        <b-icon
                          icon="three-dots-vertical"
                          aria-hidden="true"
                        ></b-icon>
                      </template>
                      <b-dropdown-item @click="onPopup2(item)"
                        ><router-link to="">Edit</router-link></b-dropdown-item
                      >
                      <b-dropdown-item href="#"
                        ><router-link :to="'/user-details/' + item['_id']"
                          >DETAILS</router-link
                        ></b-dropdown-item
                      >

                      <b-dropdown-item
                        href="#"
                        @click="onPopup3(item)"
                        v-if="
                          (userdata.roleId == 1) &
                            (item.statusId == 2 || item.statusId == 3)
                        "
                      >
                        {{
                          item.statusId == 2
                            ? "Deactivate"
                            : item.statusId == 3
                            ? "Activate"
                            : ""
                        }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>

          <template v-if="showLoading">
            <span class="no_data list_loader"
              >Loading<img src="@/assets/images/loader.gif"
            /></span>
          </template>

          <template v-if="usersList.length <= 0 && loaded && !showLoading">
            <span class="no_data">No Users Found!</span>
          </template>

          <!-- <template v-else>
                        <tr>
                            <td class="center-align" colspan="7">No Clients Found!</td>
                        </tr>
                    </template> -->
        </div>
        <div class="pagination_wrap">
          <div v-show="usersList.length > 0">
            <div class="pagination_content">
              <h6>Show Records</h6>
              <multiselect
                :multiple="false"
                @input="changePerPage()"
                placeholder="Page"
                :close-on-select="true"
                :clear-on-select="false"
                :hide-selected="true"
                :preserve-search="true"
                v-model="perPage"
                :options="perPeges"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span
                    class="multiselect__selectcustom"
                    v-if="values.length && !isOpen"
                    >{{ values.length }} selected</span
                  >
                  <span
                    class="multiselect__selectcustom"
                    v-if="values.length && isOpen"
                  ></span>
                </template>
              </multiselect>
            </div>
          </div>
          <b-pagination
            v-if="usersList.length > 0"
            v-model="page"
            :total-rows="totalPages + 1"
            :per-page="perPage"
            pills
            first-number
            last-number
          ></b-pagination>
        </div>

        <b-modal ref="my-modal" centered hide-footer hide-header>
          <div class="create_popup">
            <div class="modal-body">
              <div class="modal_left">
                <div class="modal_title">
                  <h3>
                    Internal<br />
                    Users
                  </h3>
                </div>
                <figure><img src="@/assets/images/client_vector.svg" /></figure>
                <span class="userlabel"
                  ><img src="@/assets/images/user_group.svg"
                /></span>
              </div>
              <div class="modal_right">
                <b-button
                  class="close"
                  variant="outline-danger"
                  block
                  @click="hideModal"
                  ><span aria-hidden="true">&times;</span></b-button
                >
                <h2>Add User</h2>
                <form autocomplete="off">
                  <ValidationObserver
                    ref="addUserForm"
                    v-slot="{ invalid, validated, handleSubmit, validate }"
                  >
                    <div class="form-group name-group">
                      <ValidationProvider
                        name="First Name"
                        rules="required|max:10"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          v-model="user.fName"
                          class="form-control firstname"
                          aria-describedby="emailHelp"
                          placeholder="First Name"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                        name="Last Name"
                        rules="required|max:10"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          v-model="user.lName"
                          class="form-control lastname"
                          id="LastName"
                          aria-describedby="emailHelp"
                          placeholder="Last Name"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>

                      <!--<input type="text" class="form-control firstname" id="FirstName" aria-describedby="emailHelp" placeholder="First Name">
                                <input type="text" class="form-control lastname" id="LastName" aria-describedby="emailHelp" placeholder="Last Name"> -->
                    </div>
                    <div class="form-group">
                      <ValidationProvider
                        name="Email"
                        rules="required|email"
                        v-slot="{ errors }"
                      >
                        <input
                          type="email"
                          solo
                          v-model="user.email"
                          class="form-control email"
                          label="Email"
                          placeholder="Email"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group phone-group">
                      <ValidationProvider
                        name="Phone Number"
                        rules="required|phonenumber"
                        v-slot="{ errors }"
                      >
                        <VuePhoneNumberInput
                          default-country-code="US"
                          :no-example="true"
                          :no-country-selector="true"
                          @update="updatePhoneNumber"
                          :required="true"
                          v-model="user.phone"
                        />
                        <span class="v-messages error-msg ddd">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group designation-group">
                      <ValidationProvider
                        name="Designation"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <multiselect
                          v-model="user.roleDetails"
                          :value="'_id'"
                          :options="user_role"
                          :searchable="true"
                          :close-on-select="true"
                          :allow-empty="false"
                          label="name"
                          placeholder="Select Designation"
                          track-by="name"
                          @input="updateRole"
                        >
                        </multiselect>
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                    <span
                      class="primary_btn"
                      @click="
                        erororVisible = false;
                        validate();
                        handleSubmit(addUser);
                      "
                      :disabled="
                        invalid || !validated || formSubmited || checkForm
                      "
                      >Create User</span
                    >
                  </ValidationObserver>
                </form>
              </div>
            </div>
          </div>
        </b-modal>

        <b-modal ref="my-modal2" centered hide-footer hide-header id="edituser">
          <div class="create_popup edituser">
            <div class="modal-body">
              <div class="modal_left">
                <div class="modal_title">
                  <h3>
                    Internal<br />
                    Users
                  </h3>
                </div>
                <figure><img src="@/assets/images/client_vector.svg" /></figure>
                <span class="userlabel"
                  ><img src="@/assets/images/user_group.svg"
                /></span>
              </div>
              <div class="modal_right">
                <b-button
                  class="close"
                  variant="outline-danger"
                  block
                  @click="hideModal2"
                  ><span aria-hidden="true">&times;</span></b-button
                >
                <h2>Edit User</h2>
                <form :autocomplete="false">
                  <ValidationObserver
                    ref="updateUserForm"
                    v-slot="{ invalid, validated, validate }"
                  >
                    <div class="form-group name-group">
                      <ValidationProvider
                        name="First Name"
                        rules="required|max:10"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          v-model="updateUser.fName"
                          class="form-control firstname"
                          aria-describedby="emailHelp"
                          placeholder="First Name"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                        name="Last Name"
                        rules="required|max:10"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          v-model="updateUser.lName"
                          class="form-control lastname"
                          id="LastName"
                          aria-describedby="emailHelp"
                          placeholder="Last Name"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>

                      <!--<input type="text" class="form-control firstname" id="FirstName" aria-describedby="emailHelp" placeholder="First Name">
                                <input type="text" class="form-control lastname" id="LastName" aria-describedby="emailHelp" placeholder="Last Name"> -->
                    </div>

                    <div class="form-group phone-group">
                      <ValidationProvider
                        name="Phone Number"
                        rules="required|phonenumber"
                        v-slot="{ errors }"
                      >
                        <VuePhoneNumberInput
                          default-country-code="US"
                          :no-example="true"
                          :no-country-selector="true"
                          @update="updatePhoneNumber"
                          :required="true"
                          v-model="updateUser.phone"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>

                    <span
                      class="primary_btn"
                      @click="
                        erororVisible = false;
                        validate();
                        userUpdate();
                      "
                      :disabled="
                        invalid || !validated || formSubmited || checkForm
                      "
                      >Update User</span
                    >
                  </ValidationObserver>
                </form>
              </div>
            </div>
          </div>
        </b-modal>

        <b-modal
          ref="my-modal3"
          centered
          hide-footer
          hide-header
          class="modal_popup"
        >
          <div class="modal_popup profile_popup alert_popup">
            <b-button
              class="close"
              block
              @click="
                selectedItem = null;
                hideMe();
              "
              ><span aria-hidden="true">&times;</span></b-button
            >
            <div class="profile_head">
              <label>Do you want to {{ actionTitle }} this user.. ?</label>
            </div>
            <div class="d-flex justify-content-center">
              <button
                class="secondary_btn"
                @click="
                  selectedItem = null;
                  hideMe();
                "
              >
                No
              </button>
              <button
                class="primary_btn marl15"
                @click="actionOnstatuschange()"
              >
                Yes
              </button>
            </div>
          </div>
        </b-modal>
      </div>

      <footerView ref="rout_view"></footerView>
    </div>
  </div>
</template>

<script>
import profileHeader from "@/components/ProfileHeader.vue";
import footerView from "@/components/footer.vue";

import Multiselect from "vue-multiselect-inv";
import tableheader from "@/views/filters/userFilters.vue";
//import gridView from "@/views/gridView.vue";
//import GridView from './gridView.vue';
import VuePhoneNumberInput from "vue-phone-number-input";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import _ from "lodash";
//import VuePagination from "v-pagination"
//import VsPagination from "vs-pagination";

export default {
  computed: {
    checkForm() {
      if (this.user.roleId > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  components: {
    profileHeader,
    footerView,
    Multiselect,
    tableheader,
    VuePhoneNumberInput,
    //  gridView,
    // GridView,
    ValidationObserver,
    ValidationProvider,
    // VuePagination
    //VsPagination,
  },
  data() {
    return {
      loaded: false,
      callfromSort: false,
      actionTitle: "Activate",
      selectedItem: null,
      userdata: null,
      isLoaded: false,
      loginUser: null,
      page: 1,
      perPage: 25,
      totalPages: 0,
      usersList: [],
      sortKey: {},
      perPeges: [10, 25, 50, 75, 100],
      filterData: { roleIds: [], statusIds: [], createdOnRange: [] },
      serch_title: "",
      formSubmited: false,
      user_role: [],
      user: {
        fName: "",
        lName: "",
        roleId: [],
        email: "",
        phoneCode: "+1",
        phone: "",
      },
      sortKeys: {
        name: 1,
        statusName: 1,
        createdOn: -1,
        email: 1,
        roleName: 1,
      },
    };
  },
  methods: {
    changedGlobalclient(cl) {
      this.log(cl);
      //this.$refs.rout_view.serchTitle(cl);
    },
    updatePhoneNumber(item) {
      if (item.isValid) {
        this.user.phoneCode = item.countryCallingCode;
        this.user.phone = item.nationalNumber;
      }
    },

    getList() {
      this.loaded = false;
      let postData = {
        filters: {
          title: this.serch_title,
          roleIds: this.filterData.roleIds,
          statusIds: this.filterData.statusIds,
          createdOnRange: this.filterData.createdOnRange,
        },
        sorting: this.sortKey, //{"path": "name","order": -1	},
        page: this.page,
        perpage: this.perPage,
      };
      if (!this.callfromSort) this.usersList = [];
      this.callfromSort = false;

      this.$store
        .dispatch("getUsersList", postData)
        .then((response) => {
          this.usersList = response.list;
          //let count = response.totalCount;
          this.totalPages = response.totalCount; //Math.ceil(response.totalCount / this.perPage);

          this.usersList.forEach((item) => {
            if (item.assignedClients && item.assignedClients.length > 0) {
              let arr = [];
              item.assignedClients.forEach((item2) => {
                arr = item2.name.split(" ");
                if (arr.length > 1) {
                  item2.shortName = arr[0].charAt(0).concat(arr[1].charAt(0));
                } else if (arr.length == 1) {
                  item2.shortName = arr[0].slice(0, 2);
                }
              });
            }
          });

          this.loaded = true;
        })
        .catch((err) => {
          this.log(err);
        });
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    hideModal2() {
      this.$refs["my-modal2"].hide();
    },
    onPopup2(item) {
      this.$refs["my-modal2"].show();
      this.formSubmited = false;
      this.updateUser = item;
      try {
        if (_.has(this.$refs, "updateUserForm")) {
          this.$refs["updateUserForm"].reset();
        }
      } catch (err) {
        this.log(err);
      }
    },
    async userUpdate() {
      const valid = await this.$refs.updateUserForm.validate();
      let payloadData = this.updateUser;
      payloadData.userId = this.updateUser._id;

      /*payloadData ={
          "userId":"",
            "fName": "CPA",
            "lName": "Employee1",
            "phone": "4234233322",
            "phoneCode": "+1",
            "gender": "F",
            "photo": ''
          }
          */

      if (valid) {
        this.$store
          .dispatch("updateProfile", payloadData)
          .then((res) => {
            this.notify({
              message: res.data.result.message,
              title: "Success",
              type: "success",
            });
            this.callfromSort = true;
            this.getList();
            this.hideModal2();
          })
          .catch((err) => {
            this.notify({ message: err, title: "Error", type: "error" });
          });
      }
    },
    hideMe() {
      this.$refs["my-modal3"].hide();
    },
    changePerPage() {
      this.page = 1;
      this.callfromSort = true;
      this.getList();
    },
    applyFilters(filterData) {
      this.filterData.roleIds = filterData.roleIds;
      this.filterData.statusIds = filterData.statusIds;
      this.filterData.createdOnRange = filterData.createdOnRange;

      this.getList();
    },
    clearFilters() {
      this.serch_title = "";
      this.filterData = { roleIds: [], statusIds: [], createdOnRange: [] };
      this.getList();
    },

    serchTitle() {
      this.page = 1;
      this.callfromSort = true;
      this.getList();
    },

    getMaterData(category) {
      let payLoad = {
        matcher: {
          searchString: "",
        },
        page: 1,
        perpage: 10000000000,
        category: category, // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
      };
      this.$store
        .dispatch("getMaterData", payLoad)
        .then((res) => {
          if ("user_role" == category) {
            let roles = _.cloneDeep(res.list);
            //if loggied in user is super admin he can create only support team(2) AND CA admin (3)
            if (this.userdata.roleId == 1) {
              this[category] = _.filter(roles, (rol) => {
                //return rol._id ==2 || rol._id ==3
                return rol._id == 2;
              });
            }

            //if loggied in user is CA admin he can create only Employes(4)
            if (this.userdata.roleId == 3) {
              this[category] = _.filter(roles, { _id: 4 });
            }
          } else {
            this[category] = res.list;
          }
        })
        .catch((err) => {
          this.log(err);
        });
    },

    onPopup() {
      this.$refs["my-modal"].show();
      this.formSubmited = false;
      this.user = {
        fName: "",
        lName: "",
        roleId: null,
        roleDetails: null,
        email: "",
        phoneCode: "+1",
        phone: "",
      };
      try {
        if (_.has(this.$refs, "addUserForm")) {
          this.$refs["addUserForm"].reset();
        }
      } catch (err) {
        this.log(err);
      }
    },

    updateRole(item) {
      if (_.has(item, "_id")) {
        this.user.roleId = item._id;
      }
    },
    async addUser() {
      const valid = await this.$refs.addUserForm.validate();
      if (valid) {
        this.$store
          .dispatch("addUser", this.user)
          .then((res) => {
            this.notify({
              message: res.data.result.message,
              title: "Success",
              type: "success",
            });
            this.$refs["my-modal"].hide();
            this.callfromSort = true;
            this.getList();
          })
          .catch((err) => {
            this.notify({ message: err, title: "Error", type: "error" });
          });
      }
    },
    sortMe(sort_key = "") {
      this.sortKey = { path: "createdOn", order: 1 };
      if (sort_key != "") {
        this.sortKeys[sort_key] = this.sortKeys[sort_key] == 1 ? -1 : 1;
        this.sortKey = {};

        this.sortKey["path"] = sort_key;
        this.sortKey["order"] = this.sortKeys[sort_key] == 1 ? -1 : 1;
        this.callfromSort = true;
        this.getList();
      }
    },

    onPopup3(item) {
      // item.statusId==2 , 'inactive':item.statusId== 3

      if (item["statusId"] == 2) {
        this.actionTitle = "Deactivate";
      }
      this.selectedItem = item;
      this.$refs["my-modal3"].show();
    },
    actionOnstatuschange() {
      //alert(JSON.stringify(this.selectedItem));
      let postData = {};
      postData["userId"] = this.selectedItem["_id"];
      if (this.selectedItem["statusId"] == 2) {
        postData["statusId"] = 3;
      }
      if (this.selectedItem["statusId"] == 3) {
        postData["statusId"] = 2;
      }
      this.$store
        .dispatch("updateUserStatus", postData)
        .then((res) => {
          this.$refs["my-modal3"].hide();
          this.notify({
            message: res.data.result.message,
            title: "Success",
            type: "success",
          });
          this.callfromSort = true;
          this.getList();
        })
        .catch((err) => {
          // this.addClientModel =false;
          this.notify({ message: err, title: "Error", type: "error" });
        });
    },
  },

  mounted() {
    this.userdata = this.$store.getters["auth/getuser"];
    this.isLoaded = true;
    this.sortKey = { path: "createdOn", order: -1 };
    this.getMaterData("user_role");
    this.getList();
    this.sortKeys = {
      name: 1,
      statusName: 1,
      createdOn: -1,
      email: 1,
      roleName: 1,
    };
  },
  watch: {
    page: function(val) {
      this.log(val);
      this.getList();
    },
  },
  beforeCreate() {
    this.userdata = this.$store.getters["auth/getuser"];
  },
};
</script>
